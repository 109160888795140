export default {
  home: {
    newSimulation: 'Home_Botao_NovaSimulacao',
    editSimulation: 'Home_Botao_EditarSimulacao',
    copySimulation: 'Home_Botao_CopiarSimulacao',
    deleteSimulation: 'Home_Botao_ExcluirSimulacao',
    simulations: 'Home_Botao_Simulacoes',
    audience: 'Home_Botao_Audiencia',
    competitive: 'Home_Botao_Analise_Competitiva',
    users: 'Home_Botao_EngrenagemAdmin',
    initSimulation: 'Home_Botao_Iniciar_NovaSimulacao',
    duplicateSimulation: 'Home_Botao_Iniciar_DuplicarSimulacao'
  },
  tabs: {
    filters: 'Abas_Botao_Filtros',
    ranking: 'Abas_Botao_Ranking',
    planning: 'Abas_Botao_Planejamento',
    summary: 'Abas_Botao_Resumo',
    copyScenery: 'Abas_Botao_DuplicarCenario',
    addScenery: 'Abas_Botao_AddCenario',
    removeScenery: 'Abas_Botao_RemoverCenario',
    competitiveConsolided: 'Abas_Botao_Visao_Consolidada',
    competitiveComparative: 'Abas_Botao_Visao_Comparativa',
    audienceOpenTvChannels: 'Abas_Botao_TvAbertaEmissoras',
    audiencePayTvChannels: 'Abas_Botao_PayTVEmissoras',
    audienceOpenTvPrograms: 'Abas_Botao_TvAbertaProgramas',
    audiencePayTvPrograms: 'Abas_Botao_PayTVProgramas'
  },
  simulation: {
    save: 'Simulacao_Botao_Salvar',
    update: 'Simulacao_Botao_Atualizar',
    export: 'Simulacao_Botao_Exportar_Txt',
    redirect_ads: 'Simulacao_Botao_Redirecionar_Para_o_Ads'
  },
  filters: {
    closedPeriod: 'Filtros_Botao_PeríodoFechado',
    searchAndRank: 'Filtros_Botao_BuscarRankear'
  },
  ranking: {
    addPlanning: 'Ranking_Botao+',
    removePlanning: 'Ranking_Botao-',
    exportXlsx: 'Ranking_Botao_ExportarPlanilha',
    hideDaysOfWeek: 'Ranking_Botao_OcultarDias'
  },
  planning: {
    removePlanning: 'Planejamento_Botao-',
    duplicateProgram: 'Planejamento_Botao_DuplicarPrograma',
    exportXlsx: 'Planejamento_Botao_ExportarPlanilha',
    distributeDiscount: 'Planejamento_Botao_DistribuirDesconto',
    exportMapOfMedia: 'Planejamento_Botao_ExportarMapa',
    hideDaysOfWeek: 'Planejamento_Botao_OcultarDias',
    exportPVI: 'Planejamento_Botao_ExportarPVI'
  },
  summary: {
    firstExportXlsx: 'Resumo_Botao_ExportarPlanilhaTargetPrimario',
    secondExportXlsx: 'Resumo_Botao_ExportarPlanilhaTargetSecundario',
    firstExpandChannel: 'Resumo_Botao_ExpandirListaTargetPrimario',
    secondExpandChannel: 'Resumo_Botao_ExpandirListaTargetSecundario'
  },
  competitive: {
    filter: 'Analise_Competitiva_Botao_Filtrar',
    clearFilter: 'Analise_Competitiva_Botao_LimparFiltros'
  },
  audience: {
    openTV: {
      filter: 'Audiencia_Botao_Filtrar',
      clearFilter: 'Audiencia_Botao_LimparFiltros',
      channels: {
        comparative: 'Audiencia_TvAberta_Aba_Emissoras_Botao_Comparativo',
        radiography: 'Audiencia_TvAberta_Aba_Emissoras_Botao_Radiografia'
      },
      programs: {
        ranking: 'Audiencia_TvAberta_Aba_Programas_Botao_Ranking',
        radiography: 'Audiencia_TvAberta_Aba_Programas_Botao_Radiografia'
      }
    },
    payTV: {
      menu: 'Audiência_Botão_PayTV',
      filter: 'Audiencia_Botao_Filtrar',
      clearFilter: 'Audiencia_Botao_LimparFiltros',
      channels: {
        comparative: 'Audiencia_PayTV_Aba_Emissoras_Botao_Comparativo',
        radiography: 'Audiencia_PayTV_Aba_Emissoras_Botao_Radiografia'
      },
      programs: {
        ranking: 'Audiencia_PayTV_Aba_Programas_Botao_Ranking',
        radiography: 'Audiencia_PayTV_Aba_Programas_Botao_Radiografia'
      }
    },
    // MENU ATUAL
    openTV_Regular: 'Audiência_Botão_TvAbertaPraçaRegular',
    openTV_Notebook: 'Audiência_Botão_TvAbertaPraçaCaderno',
    digital: 'Audiência_Botão_Digital',
    openTV_Clusters: 'Audiência_Botão_TvAbertaClusters',
    help: 'Audiência_Botão_MaterialApoio',
    filter: 'Audiência_Botao_Filtrar',
    clearFilter: 'Audiência_Botao_LimparFiltros'
  }
};
