import React from 'react';
import { Grid } from '@mui/material';

import paths from '../../../../../routes/paths';
import SubMenuTabs from '../../../Tabs/OpenTv/SubMenuTabs';
import IconComparativeChannels from '../../../../../assets/images/channels-comparative-icon.svg';
import IconSummary from '../../../../../assets/images/summary.svg';
import { IAudiencebyChannelsContainerProps } from './types';
import classesGTM from '../../../../../config/classesGTM';
import AudiencebyPayTvChannelsVerticalFiltersComparative from './Comparative/AudiencePayTvChannelsComparativeVerticalFilter';
import AudiencebyPayTvChannelsVerticalFiltersRadiography from './Radiography/AudiencePayTvChannelsRadiographyVerticalFilter';

const { childrens, path } = paths.home.childrens.audience;

const AudiencebyPayTvChannelsContainer: React.FC<
  IAudiencebyChannelsContainerProps & {
    tab: 'channels' | 'programs';
    subtab: 'comparative' | 'radiography';
  }
> = ({
  target,
  timeRange,
  timeRanges,
  weekDays,
  programmingGenders,
  newFilter,
  setNewFilter,
  setTarget,
  setTimeRange,
  setTimeRanges,
  setWeekDays,
  setProgrammingGenders,
  children,
  tab,
  subtab
}) => {
  const additionalProps = {
    target,
    weekDays,
    programmingGenders,
    newFilter,
    setNewFilter,
    setTarget,
    setWeekDays,
    setProgrammingGenders
  };

  return (
    <>
      <SubMenuTabs
        items={[
          {
            icon: IconComparativeChannels,
            itemId: 1,
            title: 'Comparativo da Concorrência',
            path: `${path}/${childrens.payTv.path}/${childrens.payTv.childrens.channels.path}/${childrens.payTv.childrens.channels.childrens.comparative.path}`,
            visibled: true,
            classes: {
              tagManager: classesGTM.audience.payTV.channels.comparative
            }
          },
          {
            icon: IconSummary,
            itemId: 2,
            title: 'Radiografia no Período',
            path: `${path}/${childrens.payTv.path}/${childrens.payTv.childrens.channels.path}/${childrens.payTv.childrens.channels.childrens.radiography.path}`,
            visibled: true,
            classes: {
              tagManager: classesGTM.audience.payTV.channels.radiography
            }
          }
        ]}
      />
      <Grid container height="100%" padding={5} paddingTop={0} spacing={0}>
        <Grid
          className="competitive__container"
          item
          direction="row"
          width="100%"
          border="none"
        >
          {tab === 'channels' && subtab === 'comparative' ? (
            <AudiencebyPayTvChannelsVerticalFiltersComparative
              {...additionalProps}
              setTimeRanges={setTimeRanges}
              timeRanges={timeRanges}
            />
          ) : (
            <AudiencebyPayTvChannelsVerticalFiltersRadiography
              {...additionalProps}
              timeRange={timeRange}
              setTimeRange={setTimeRange}
            />
          )}
        </Grid>
        <Grid container direction="column" item width="calc(100%)">
          <Grid height="calc(100% - 60px)" item width="100%" paddingTop={5}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AudiencebyPayTvChannelsContainer;
