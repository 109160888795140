import './Univers.scss';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';
import { useLocation } from 'react-router-dom';
import uniqBy from 'lodash/uniqBy';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useCountUp } from 'react-countup';
import { ButtonIcon } from '../ButtonApp';
import { getUuid } from '../../helpers/UUID';
import {
  formatterCurrency,
  formatterNumber,
  formatterPercent
} from '../../helpers/Formatters';
import ExpandMoreIcon from '../../assets/images/expand-more.svg';
import UniversInfo from './UniversInfo';
import { calcTotalizers } from '../../helpers/CalcsSimulation';
import usePlanningRedux from '../Planning/usePlanningRedux';
import { PlanningRow } from '../../store/modules/Planning/Planning.types';
import useFilters from '../Filters/useFilters';
import { TypeTV } from '../../services/shared/Api.service';
import { TV_ABERTA_GLOBO } from '../../helpers/Utils';
import paths from '../../routes/paths';
import { useAppSelector, useAppDispatch, useAppMediaQuery } from '../../hooks';
import { SetTotalizers } from '../../store/modules/Planning/Planning.actions';

interface BroadcastInfo {
  id: string;
  channel: string;
  viewer: string | number;
  source: string;
}

const Univers = () => {
  const downLg = useAppMediaQuery('lg', 'down');
  const dispatch = useAppDispatch();
  const planning = usePlanningRedux().currentPlanning;
  const { getCurrentFilters } = useFilters();
  const currentFilter = getCurrentFilters();
  const refTimeout = useRef<NodeJS.Timeout | string | number | undefined>();
  const { simulation } = useAppSelector((state) => state);
  const mountScenery = simulation.currentScenery;
  const mountSceneryId = simulation.currentSceneryId;
  const { path, childrens } = paths.home.childrens.simulation;
  const pathPlanning = `${path}/${mountScenery}/${childrens.planning}`;

  const callFormatterCurrency = useCallback(
    (val: number) => formatterCurrency(val, { fractionDigits: 1 }),
    []
  );
  const callFormatterNumber = useCallback(
    (val: number) => formatterNumber(val, { fractionDigits: 5 }),
    []
  );
  const callFormatterPercent = useCallback(
    (val: number) => formatterPercent(val, { fractionDigits: 3 }),
    []
  );

  const refSumNegotiated = useRef(null);
  const sumNegotiated = useCountUp({
    ref: refSumNegotiated,
    startVal: planning.totalNegotiated,
    end: planning.totalNegotiated,
    delay: 0,
    duration: 2,
    decimals: 2,
    formattingFn: callFormatterCurrency
  });

  const refDiscountAvg = useRef(null);
  const discountAvg = useCountUp({
    ref: refDiscountAvg,
    startVal: planning.avgDiscount,
    end: planning.avgDiscount,
    delay: 0,
    duration: 2,
    decimals: 3,
    formattingFn: callFormatterPercent
  });

  const refSumTotal = useRef(null);
  const sumTotal = useCountUp({
    ref: refSumTotal,
    startVal: planning.totalAmount,
    end: planning.totalAmount,
    delay: 0,
    duration: 2,
    decimals: 2,
    formattingFn: callFormatterCurrency
  });

  const refSumInsertions = useRef(null);
  const sumInsertions = useCountUp({
    ref: refSumInsertions,
    startVal: planning.totalInsertions,
    end: planning.totalInsertions,
    delay: 0,
    duration: 2
  });

  const refSumGrpOrTrp = useRef(null);
  const sumGrpOrTrp = useCountUp({
    ref: refSumGrpOrTrp,
    startVal: planning.totalGrpOrTrp,
    end: planning.totalGrpOrTrp,
    delay: 0,
    duration: 2,
    decimals: 5,
    formattingFn: callFormatterNumber
  });

  const sumNegotiatedHandles = useRef(sumNegotiated);
  const discountAvgHandles = useRef(discountAvg);
  const sumTotalHandles = useRef(sumTotal);
  const sumInsertionsHandles = useRef(sumInsertions);
  const sumGrpOrTrpHandles = useRef(sumGrpOrTrp);

  const getMetricsPerTarget = useCallback(
    (program: PlanningRow | undefined) =>
      program?.metricsPerTarget.find(
        (metric) => metric.target === planning.target
      ),
    [planning.target]
  );

  const numOfViewersMessage = useMemo(() => {
    const messageOfViewers: BroadcastInfo[] = [];
    const typeTV = currentFilter?.data?.typeTV;

    if (typeTV === TypeTV.CLOSED) {
      return [
        ...messageOfViewers,
        {
          id: getUuid(),
          channel: 'Telespectadores',
          viewer: formatterNumber(
            getMetricsPerTarget(
              planning.planningPrograms.find(
                (c) => c.channel !== TV_ABERTA_GLOBO
              )
            )?.univTarget || 0,
            { fractionDigits: 0 }
          ),
          source: 'Universo projetado baseado no EDTV 2024'
        }
      ];
    }

    const channels = uniqBy(planning.planningPrograms, 'channel');

    return channels.map((item) => ({
      id: getUuid(),
      channel: item.channel,
      viewer: formatterNumber(
        getMetricsPerTarget(
          planning.planningPrograms.find((c) => c.channel === item.channel)
        )?.univTarget || 0,
        { fractionDigits: 0 }
      ),
      source: 'Universo projetado baseado no EDTV 2024'
    }));
  }, [currentFilter, planning.planningPrograms, getMetricsPerTarget]);

  useEffect(() => {
    const {
      totalAmount,
      totalNegotiated,
      avgDiscount,
      totalInsertions,
      totalTrpOrGrp
    } = calcTotalizers(planning.planningPrograms, planning.target);

    sumNegotiatedHandles.current.update(totalNegotiated);

    sumTotalHandles.current.update(totalAmount);

    discountAvgHandles.current.update(avgDiscount);

    sumInsertionsHandles.current.update(totalInsertions);

    sumGrpOrTrpHandles.current.update(totalTrpOrGrp);

    dispatch(
      SetTotalizers(
        totalAmount,
        totalNegotiated,
        avgDiscount,
        totalInsertions,
        totalTrpOrGrp,
        mountSceneryId
      )
    );
  }, [planning.planningPrograms, dispatch, planning.target, mountSceneryId]);

  const [expanded, setExpanded] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded);
    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
    }
  };

  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.match(pathPlanning)) setVisible(true);
    else setVisible(false);
  }, [pathPlanning, location.pathname]);

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.match(pathPlanning)) {
      setExpanded(true);
      refTimeout.current = setTimeout(() => {
        setExpanded(false);
      }, 5000);
    }

    return () => {
      clearTimeout(refTimeout.current);
    };
  }, [pathPlanning, location.pathname]);

  const ExpandButton = (
    <ButtonIcon
      icon={ExpandMoreIcon}
      svgClassName="accordion__button accordion__button--icon"
    />
  );

  // TODO: verificar css do component para mobile
  // TODO: separar os components accordion, totalizadores e universos
  return (
    <Box
      className="univers"
      sx={{
        marginTop: expanded ? '0px' : '60px',
        height: expanded ? '255px' : '80px',
        display: visible ? undefined : 'none'
      }}
    >
      <Accordion
        classes={{ root: 'univers_accordion' }}
        expanded={expanded}
        onChange={handleChange}
      >
        <AccordionSummary
          aria-controls="panel1bh-content"
          classes={{
            root: 'univers_accordion__summary',
            content: 'univers_accordion__content'
          }}
          expandIcon={ExpandButton}
          id="panel1bh-header"
        >
          <div
            className={`d-flex justify-center ${
              downLg ? 'flex-column fill-width py-4' : ''
            }`}
          >
            <div className="d-flex justify-end">
              <div className="totalizers__stats__wrapper">
                <span className="totalizers__stats__wrapper--field">
                  Inserções:
                </span>
                <span
                  ref={refSumInsertions}
                  className="totalizers__stats__wrapper--value"
                />
              </div>
            </div>
            <div className="d-flex justify-end">
              <div className="totalizers__stats__wrapper">
                <span className="totalizers__stats__wrapper--field">
                  Valor total:
                </span>
                <span
                  ref={refSumTotal}
                  className="totalizers__stats__wrapper--value"
                />
              </div>
            </div>
            <div className="d-flex justify-end">
              <div className="totalizers__stats__wrapper">
                <span className="totalizers__stats__wrapper--field">
                  Desconto médio:
                </span>
                <span
                  ref={refDiscountAvg}
                  className="totalizers__stats__wrapper--value"
                />
              </div>
            </div>
            <div className="d-flex justify-end">
              <div className="totalizers__stats__wrapper">
                <span className="totalizers__stats__wrapper--field">
                  Valor negociado:
                </span>
                <span
                  ref={refSumNegotiated}
                  className="totalizers__stats__wrapper--value"
                />
              </div>
            </div>
            <div className="d-flex justify-end">
              <div className="totalizers__stats__wrapper">
                <span className="totalizers__stats__wrapper--field">
                  GRP/TRP:
                </span>
                <span
                  ref={refSumGrpOrTrp}
                  className="totalizers__stats__wrapper--value"
                />
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails classes={{ root: 'univers_accordion__details' }}>
          <div className="d-flex justify-center">
            {numOfViewersMessage.map((univers, idx) => (
              <Box key={univers.id} display="flex">
                <UniversInfo
                  channel={univers.channel}
                  source={univers.source}
                  viewer={univers.viewer}
                />
                {idx !== numOfViewersMessage.length - 1 && (
                  <Divider
                    className="mr-6 ml-6 univers-info__divider"
                    flexItem
                    orientation="vertical"
                  />
                )}
              </Box>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Univers;
